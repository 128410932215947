import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useRecoilValue } from 'recoil';

import './index.less';

import { Elma365BpmTemplate, Elma365Namespace, Elma365IntegrationSettingsSchema } from '../../../../../../api';
import SbSpin from '../../../../../simple-bot/components/common/SbSpin';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import SbPanel from '../../../../../simple-bot/components/common/SbPanel';
import { instanceOfElma365IntegrationSettingsSchema } from '../../../utils';
import { botSettingsSelector } from '../../../../../recoil/scenarioStructure';
import { elma365BpmTemplateApi, elma365MetadataApi } from '../../../../../apis';

interface IElma365BpmTemplateSelectorProps {
  bpmTemplateCode?: string | null;
  bpmTemplateNamespace?: string | null;
  onChange: (bpmTemplate?: Elma365BpmTemplate) => void;
  onSelectedBpmTemplateUpdated: (bpmTemplate?: Elma365BpmTemplate) => void;
}

const filterBpmTemplateList = (bpmTemplateList: Elma365BpmTemplate[]) =>
  bpmTemplateList.filter((t) => !t.draft && !t.readonly);

const getNamespaceWithCode = (namespace: string, code: string) => `${namespace}|${code}`;

const getNamespaceAndCode = (namespaceWithCode: string) => namespaceWithCode.split('|');

const Elma365BpmTemplateSelector: React.FC<IElma365BpmTemplateSelectorProps> = ({
  bpmTemplateCode,
  bpmTemplateNamespace,
  onChange,
  onSelectedBpmTemplateUpdated,
}) => {
  const botSettings = useRecoilValue(botSettingsSelector);

  const [bpmTemplateListLoading, setBpmTemplateListLoading] = useState(false);
  const [bpmTemplateListAll, setBpmTemplateListAll] = useState<Elma365BpmTemplate[]>([]);
  const [selectedBpmTemplate, setSelectedBpmTemplate] = useState<Elma365BpmTemplate>();

  const [namespaceListLoading, setNamespaceListLoading] = useState(false);
  const [namespaceListAll, setNamespaceListAll] = useState<Elma365Namespace[]>();
  const [selectedNamespace, setSelectedNamespace] = useState<Elma365Namespace>();

  const bpmTemplateList = filterBpmTemplateList(bpmTemplateListAll);

  const integrationSettings = botSettings?.integrations.find((i) =>
    instanceOfElma365IntegrationSettingsSchema(i)
  ) as Elma365IntegrationSettingsSchema;
  const integrationSettingsNotSet = !integrationSettings?.apiUrl || !integrationSettings?.xToken;

  const tryLoadNamespaceList = async (baseUrl?: string, xToken?: string) => {
    if (!baseUrl || !xToken) {
      setNamespaceListAll([]);
      return;
    }

    setNamespaceListLoading(true);
    try {
      const response = await elma365MetadataApi.getElma365NamespaceSchemeList(baseUrl, xToken);
      setNamespaceListAll(response.data);

      const selectedNamespace = response.data.find((t) => bpmTemplateNamespace?.includes(t.code));
      setSelectedNamespace(selectedNamespace);
    } catch (e) {
      setNamespaceListAll([]);
    }
    setNamespaceListLoading(false);
  };

  const tryLoadBpmTemplateList = async (baseUrl?: string, xToken?: string) => {
    if (!baseUrl || !xToken) {
      setBpmTemplateListAll([]);
      return;
    }

    setBpmTemplateListLoading(true);
    if (selectedNamespace) {
      try {
        const response = await elma365MetadataApi.getElma365BpmTemplateSchemeList(
          selectedNamespace.code,
          baseUrl,
          xToken
        );
        setBpmTemplateListAll(response.data);
      } catch (e) {
        setBpmTemplateListAll([]);
      }
    }

    if (!namespaceListAll?.length) {
      try {
        const response = await elma365BpmTemplateApi.getElma365BpmTemplateList(baseUrl, xToken);
        setBpmTemplateListAll(response.data);
      } catch (e) {
        setBpmTemplateListAll([]);
      }
    }
    setBpmTemplateListLoading(false);
  };

  const onInit = () => {
    tryLoadNamespaceList(integrationSettings?.apiUrl, integrationSettings?.xToken).finally();
  };
  useEffect(onInit, [integrationSettings]);

  const loadBpmTemplates = () => {
    if (selectedNamespace || namespaceListAll) {
      tryLoadBpmTemplateList(integrationSettings?.apiUrl, integrationSettings?.xToken).finally();
    }
  };
  useEffect(loadBpmTemplates, [selectedNamespace, namespaceListAll]);

  const updateSelectedBpmTemplateAsync = async (baseUrl?: string, xToken?: string) => {
    let selectedBpmTemplate: Elma365BpmTemplate | undefined = undefined;

    if (baseUrl && xToken && bpmTemplateNamespace && bpmTemplateCode) {
      try {
        const response = await elma365MetadataApi.getElma365BpmTemplateScheme(
          bpmTemplateNamespace,
          bpmTemplateCode,
          baseUrl,
          xToken
        );
        selectedBpmTemplate = response.data;
      } catch (e) {
        //empty
      }
    }

    if (bpmTemplateList.length && !selectedBpmTemplate) {
      selectedBpmTemplate = bpmTemplateList.find(
        (t) => t.namespace === bpmTemplateNamespace && t.code === bpmTemplateCode
      );
    }

    setSelectedBpmTemplate(selectedBpmTemplate);
    onSelectedBpmTemplateUpdated(selectedBpmTemplate);
    onChange(selectedBpmTemplate);
  };

  const updateSelectedBpmTemplate = () => {
    updateSelectedBpmTemplateAsync(integrationSettings?.apiUrl, integrationSettings?.xToken).finally();
  };
  useEffect(updateSelectedBpmTemplate, [bpmTemplateNamespace, bpmTemplateCode]);

  const onBpmTemplateIdChange = async (value: SelectValue) => {
    const namespaceWithCode = value as string;
    const [namespace, code] = getNamespaceAndCode(namespaceWithCode);
    const bpmTemplate = bpmTemplateList.find((t) => t.namespace === namespace && t.code === code);
    onChange(bpmTemplate);
  };

  const onNamespaceCodeChange = (value: SelectValue) => {
    const namespaceWithCode = value as string;
    const [namespace, code] = getNamespaceAndCode(namespaceWithCode);
    setSelectedNamespace(namespaceListAll?.find((n) => n.namespace === namespace && n.code === code));
    setSelectedBpmTemplate(undefined);
    onSelectedBpmTemplateUpdated(undefined);
  };

  const bpmTemplateListClasses = ['field', 'sb-elma365-bpm-template-selector__bpm-template-list'];
  bpmTemplateListLoading && bpmTemplateListClasses.push('sb-elma365-bpm-template-selector__bpm-template-list_loading');
  const namespaceListClasses = ['field', 'sb-elma365-bpm-template-selector__namespace-list'];
  namespaceListLoading && namespaceListClasses.push('sb-elma365-bpm-template-selector__namespace-list_loading');

  if (integrationSettingsNotSet) {
    return (
      <SbPanel sbType="help" scrollable={false}>
        Настройте параметры интеграции ELMA365 на странице бота.
      </SbPanel>
    );
  }

  return (
    <>
      {!!namespaceListAll?.length && (
        <div className={namespaceListClasses.join(' ')}>
          <h4>Раздел:</h4>
          <SbSelect
            disabled={namespaceListLoading}
            placeholder="Выберите раздел"
            sbSize="big"
            sbType="light"
            value={
              selectedNamespace
                ? getNamespaceWithCode(selectedNamespace?.namespace, selectedNamespace?.code)
                : undefined
            }
            onChange={onNamespaceCodeChange}
          >
            {namespaceListAll.map((t) => (
              <Select.Option
                key={getNamespaceWithCode(t.namespace, t.code)}
                value={getNamespaceWithCode(t.namespace, t.code)}
              >
                {t.name}
              </Select.Option>
            ))}
          </SbSelect>
          <SbSpin />
        </div>
      )}
      {(selectedNamespace || !namespaceListAll?.length) && (
        <div className={bpmTemplateListClasses.join(' ')}>
          <h4>Бизнес-процесс:</h4>
          <SbSelect
            disabled={bpmTemplateListLoading}
            placeholder="Выберите бизнес-процесс"
            sbSize="big"
            sbType="light"
            value={
              selectedBpmTemplate
                ? getNamespaceWithCode(selectedBpmTemplate?.namespace, selectedBpmTemplate?.code)
                : undefined
            }
            onChange={onBpmTemplateIdChange}
          >
            {bpmTemplateList.map((t) => (
              <Select.Option
                key={getNamespaceWithCode(t.namespace, t.code)}
                value={getNamespaceWithCode(t.namespace, t.code)}
              >
                {t.__name}
              </Select.Option>
            ))}
          </SbSelect>
          <SbSpin />
        </div>
      )}
    </>
  );
};

export default Elma365BpmTemplateSelector;
