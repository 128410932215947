(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.elma) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.elma = {
    assistant: {
      info: {
        configuration: {
          client: {
            services: {
              botManager: {
                publicUrl: 'http://localhost',
              },
              knowledgeBase: {},
              authServer: {},
              liveChat: {},
              devDocs: {},
            },
          },
        },
      },
    },
  };
})();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const CLIENT_CONFIG = window.elma.assistant.info.configuration.client;

export const PUBLIC_URL: string = CLIENT_CONFIG.services.botManager.publicUrl;

export const BASE_PATH: string = new URL(PUBLIC_URL).pathname.replace(/\/$/, '');

export const trimBasePath = (fullPath: string): string => (BASE_PATH ? fullPath.substring(BASE_PATH.length) : fullPath);

export const getCurrentPath = (subPath: string): string => `${BASE_PATH}${subPath}`;

export const getCurrentUrl = (fullPath: string): string => `${window.location.origin}${fullPath}`;

export const LOCALE_PATH: string = getCurrentPath('/locales/{{lng}}/{{ns}}.json');

export const getServerUrl = (publicUrl: string, subPath?: string): string =>
  subPath ? `${publicUrl}${subPath}` : publicUrl;

export const KNOWLEDGE_BASE_URL = CLIENT_CONFIG.services.knowledgeBase.publicUrl;

export const AUTH_SERVER_URL = CLIENT_CONFIG.services.authServer.publicUrl;

export const LIVE_CHAT_URL = CLIENT_CONFIG.services.liveChat.publicUrl;

export const DEV_DOCS_URL = CLIENT_CONFIG.services.devDocs.publicUrl;

export const getKnowledgeBaseUrl = (subPath?: string): string => getServerUrl(KNOWLEDGE_BASE_URL, subPath);

export const getLiveChatUrl = (subPath?: string): string => getServerUrl(LIVE_CHAT_URL, subPath);
