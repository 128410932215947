import { ContextElement, Elma365FormPropertySchema, SchemaKind, VariableType } from '../../../api';
import { Elma365FormPropertyDataTypes } from '../types';
import { generateId } from '../../components/ScenarioEditor/utils';

export const NAME_FIELD_CODE = '__name';
export const COMMENT_FIELD_CODE = '__comment';
export enum TitleGenerateMethods {
  TEMPLATE = 'template',
}

export const getFormPropertyType = (property: Elma365FormPropertySchema): string => {
  const { dataType, additionalDataType } = property;

  if (dataType === Elma365FormPropertyDataTypes.STRING) return 'Строка';
  if (dataType === Elma365FormPropertyDataTypes.TEXT) return 'Текст';
  if (dataType === Elma365FormPropertyDataTypes.MARKDOWN) return 'Markdown';

  if (dataType === Elma365FormPropertyDataTypes.INTEGER) return 'Целое число';
  if (dataType === Elma365FormPropertyDataTypes.FLOAT) return 'Дробное число';

  if (
    dataType === Elma365FormPropertyDataTypes.CHECKBOX ||
    dataType === Elma365FormPropertyDataTypes.RADIO ||
    dataType === Elma365FormPropertyDataTypes.BOOLEAN
  )
    return 'Да/Нет';

  if (dataType === Elma365FormPropertyDataTypes.LINK) return 'Ссылка';

  if (dataType === Elma365FormPropertyDataTypes.REF_ITEM) return 'Произвольное приложение';

  if (dataType === Elma365FormPropertyDataTypes.FULL_NAME) return 'ФИО';

  if (dataType === Elma365FormPropertyDataTypes.DATETIME) return 'Дата/Время';
  if (dataType === Elma365FormPropertyDataTypes.DATE) return 'Дата';
  if (dataType === Elma365FormPropertyDataTypes.TIME) return 'Время';

  if (dataType === Elma365FormPropertyDataTypes.ENUM) return 'Категория';

  if (dataType === Elma365FormPropertyDataTypes.TABLE) return 'Таблица';

  if (dataType === Elma365FormPropertyDataTypes.EMAIL) {
    if (additionalDataType === 'main') return 'Электронная почта: Главная';
    if (additionalDataType === 'home') return 'Электронная почта: Домашняя';
    if (additionalDataType === 'work') return 'Электронная почта: Рабочая';
    return 'Электронная почта';
  }

  if (dataType === Elma365FormPropertyDataTypes.PHONE) {
    if (additionalDataType === 'main') return 'Номер телефона: Главный';
    if (additionalDataType === 'home') return 'Номер телефона: Домашний';
    if (additionalDataType === 'work') return 'Номер телефона: Рабочий';
    if (additionalDataType === 'mobile') return 'Номер телефона: Мобильный';
    if (additionalDataType === 'work-fax') return 'Номер телефона: Раб. факс';
    if (additionalDataType === 'pager') return 'Номер телефона: Пейджер';
    return 'Номер телефона';
  }

  if (dataType === Elma365FormPropertyDataTypes.ACCOUNT) {
    if (additionalDataType === 'telegram') return 'Учетная запись: Telegram';
    if (additionalDataType === 'whatsapp') return 'Учетная запись: WhatsApp';
    if (additionalDataType === 'instagram') return 'Учетная запись: Instagram';
    if (additionalDataType === 'viber') return 'Учетная запись: Viber';
    if (additionalDataType === 'facebook') return 'Учетная запись: Facebook';
    if (additionalDataType === 'vkontakte') return 'Учетная запись: VK';
    return 'Учетная запись';
  }

  if (dataType === Elma365FormPropertyDataTypes.MONEY) {
    if (additionalDataType === 'RUB') return 'Деньги: ₽';
    if (additionalDataType === 'USD') return 'Деньги: $';
    if (additionalDataType === 'EUR') return 'Деньги: €';
    if (additionalDataType === 'AZN') return 'Деньги: ₼';
    if (additionalDataType === 'BYN') return 'Деньги: Br';
    if (additionalDataType === 'GBP') return 'Деньги: £';
    if (additionalDataType === 'JPY') return 'Деньги: ¥';
    if (additionalDataType === 'KZT') return 'Деньги: ₸';
    if (additionalDataType === 'CNY') return 'Деньги: 元';
    if (additionalDataType === 'GEL') return 'Деньги: ₾';
    if (additionalDataType === 'ILS') return 'Деньги: ₪';
    if (additionalDataType === 'TRY') return 'Деньги: ₺';
    if (additionalDataType === 'UAH') return 'Деньги: ₴';
    return `Деньги: ${additionalDataType}`;
  }

  if (dataType === Elma365FormPropertyDataTypes.SYS_USER) return 'Пользователь';
  if (dataType === Elma365FormPropertyDataTypes.ROLE) return 'Роль';

  if (dataType === Elma365FormPropertyDataTypes.SYS_COLLECTION) return 'Приложение';

  if (dataType === Elma365FormPropertyDataTypes.FILE) return 'Файл';

  return 'Тип не распознан';
};

export const getMatchedVariableTypes = (property: Elma365FormPropertySchema): VariableType[] => {
  const { dataType } = property;

  switch (dataType as Elma365FormPropertyDataTypes) {
    case Elma365FormPropertyDataTypes.INTEGER:
    case Elma365FormPropertyDataTypes.FLOAT:
    case Elma365FormPropertyDataTypes.MONEY:
      return [VariableType.Number];

    case Elma365FormPropertyDataTypes.CHECKBOX:
    case Elma365FormPropertyDataTypes.RADIO:
    case Elma365FormPropertyDataTypes.BOOLEAN:
      return [VariableType.Boolean];

    case Elma365FormPropertyDataTypes.DATETIME:
    case Elma365FormPropertyDataTypes.DATE:
    case Elma365FormPropertyDataTypes.TIME:
      return [VariableType.DateTime];

    case Elma365FormPropertyDataTypes.FULL_NAME:
      return [VariableType.PersonName];

    case Elma365FormPropertyDataTypes.SYS_USER:
    case Elma365FormPropertyDataTypes.SYS_COLLECTION:
    case Elma365FormPropertyDataTypes.ENUM:
      return [VariableType.ComplexObject];

    case Elma365FormPropertyDataTypes.STRING:
    case Elma365FormPropertyDataTypes.TEXT:
    case Elma365FormPropertyDataTypes.MARKDOWN:
      return [VariableType.String, VariableType.Number, VariableType.DateTime];

    case Elma365FormPropertyDataTypes.FILE:
      return [VariableType.File];

    default:
      return [VariableType.String];
  }
};

export const mapContextElementToFormProperty = (
  context: ContextElement,
  currentFormProperties: Elma365FormPropertySchema[]
): Elma365FormPropertySchema => {
  const dataType = (context.view?.data?.additionalType || context.type).toLowerCase();
  const { code, required } = context;
  let additionalDataType = context.view?.data?.type || context.view?.data?.currency;
  // TODO: убрать проверку после доработки https://git.elewise.com/elma-assistant/elma-assistant/-/issues/1581
  additionalDataType = typeof additionalDataType === 'string' ? additionalDataType : null;
  return {
    name: context.view?.name || code,
    code,
    required,
    dataType,
    additionalDataType,
    variableId: currentFormProperties.find((p) => p.code === code)?.variableId,
    id: generateId('EFP'),
    $kind: SchemaKind.Elma365FormProperty,
  };
};

export const isAllowedField = (
  field: ContextElement,
  titleGenerateMethod?: TitleGenerateMethods,
  titleTemplate?: string
): boolean => {
  if (field.readonly) return false;
  if (field.deleted) return false;
  if (field.view?.hidden) return false;
  if (field.code === NAME_FIELD_CODE && !(titleGenerateMethod === TitleGenerateMethods.TEMPLATE && titleTemplate))
    return true;
  if (field.code === COMMENT_FIELD_CODE) return true;
  if (field.code.startsWith('__') && field.view?.system) return false; // NOTE: фильтруем системные

  return true;
};
